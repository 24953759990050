.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav {
  margin-top: 2rem;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

nav ul li {
  align-self: center;
  margin: 1rem;
}

nav ul li a {
  padding: 0;
  margin: 0;
  color: #E50D0E;
  font-size: 3rem;
  text-decoration: none;
}

nav ul li:hover {
  box-shadow: inset 0 -2px 0 0 white;
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid white;
  margin: 20px auto;
  animation: arrow-down 2s ease-in-out infinite;
}

@keyframes arrow-down {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(15px);
    opacity: 1;
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}
